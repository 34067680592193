/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    h2: "h2",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "can-hearing-aids-cause-vertigo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#can-hearing-aids-cause-vertigo",
    "aria-label": "can hearing aids cause vertigo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Can Hearing Aids Cause Vertigo?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The short answer is no"), ". Symptoms of vertigo, such as feeling dizzy and ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "hearing loss"), ", can sometimes be related. However, ", React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "hearing aids"), " don't directly cause symptoms of vertigo."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While many people think a link exists between hearing loss and vertigo, hearing loss itself doesn’t cause a phantom sense of motion, spinning, or dizziness. Additionally, it doesn’t directly contribute to balance problems."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, vertigo can sometimes accompany hearing loss. You might find vertigo relief by speaking with your audiologist to discover the root of the problem so you can find a solution."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-vertigo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-vertigo",
    "aria-label": "what is vertigo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Is Vertigo?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Vertigo is a sensation of spinning even if you’re standing or sitting still. It can cause dizziness and other unpleasant symptoms. It doesn't fall under the classification of a disease. Instead, it's a byproduct of varying conditions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two main types of vertigo can occur depending on the underlying medical issue:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Central vertigo – Central vertigo often results from problems with the brain, such as tumors, a stroke, or a traumatic brain injury."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Peripheral vertigo – Peripheral vertigo involves a problem with the inner ear."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There isn’t one primary cause of vertigo. However, issues with the brain, inner ear, or sensory nerve pathway often trigger symptoms. It can also be a symptom of Meniere’s disease, an inner ear disorder, or an ear infection."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Other factors that can contribute to vertigo include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Stroke"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Migraines"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Certain medications"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear surgery"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Diabetes"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Arrhythmia"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Singles in or near the ear"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Three out of every four cases of vertigo are associated with a hearing disorder."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-vertigo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-vertigo",
    "aria-label": "symptoms of vertigo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of Vertigo"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The most common symptoms of vertigo include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Feeling pulled"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sweating"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dizziness"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ringing in the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Loss of balance"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Nausea"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Spinning sensation"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Jerky eye movements"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Vertigo can last for a few seconds or several minutes. Some people experience the sensation for several hours or over multiple days. The duration varies from one person to the next."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Additionally, some people experience mild symptoms that go away quickly. Others have severe cases of vertigo with unpredictable symptoms. The underlying cause typically determines the length of each episode."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearing-aids-help-people-with-vertigo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearing-aids-help-people-with-vertigo",
    "aria-label": "how hearing aids help people with vertigo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How Hearing Aids Help People with Vertigo"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The vestibular system is a part of the body that transmits information about movement and balance to the brain. The system includes the inner ear, which contains canals with hair cells and fluid. The hair cells act like sensors that send messages to the brain when they sense side to side, up and down, or tilting movement."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Damage to the inner ear within this system can lead to symptoms of vertigo. Hearing aids could help resolve this issue. However, some people report trouble with balance, dizziness, and other symptoms while using hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Medical experts determined hearing aids don't cause adverse side effects or cause known medical conditions. Many people must endure an adjustment period while wearing hearing aids for the first time. Your brain and the central auditory system must get used to the information it receives and new sounds you haven't been able to hear before."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A study from Washington University School of Medicine shows that hearing aids can improve balance. The participants appeared to maintain stability by receiving auditory signals while wearing hearing aids. The evidence also suggested older participants could benefit from hearing aids by lowering their risk of falling."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "get-assistance-from-an-experienced-audiologist-near-you",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-assistance-from-an-experienced-audiologist-near-you",
    "aria-label": "get assistance from an experienced audiologist near you permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get Assistance from an Experienced Audiologist Near You"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", we offer a range of innovative and ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "technologically-advanced hearing aids"), ". We understand the struggles of hearing loss and the importance of using a customized device to meet your particular needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you contact a hearing professional from hear.com, we will evaluate your condition and determine the right hearing aids for you. We focus on quality and try to give our customers life-changing solutions to their hearing problems."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re experiencing inner ear vertigo and related symptoms, ", React.createElement(_components.a, {
    href: "https://www.hear.com/d/full_page_questionnaire",
    className: "c-md-a"
  }, "take our questionnaire"), " to determine if you qualify for our no-risk hearing aid trial."), "\n", React.createElement(LandingPageCta, {
    copy: "Start your 45-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
